export function useEmailFlow() {
  const nuxtApp = useNuxtApp()

  const confirmEmailJwt = async (
    confirmationToken: string,
    userGuid: string
  ) => {
    return await nuxtApp.$api.user.confirmEmailJwt(userGuid, confirmationToken)
  }

  /**
   * @deprecated
   */
  const confirmEmail = async (confirmationToken: string) => {
    // TODO this will break when or if the PATCH CALL do not return the user object.
    return await nuxtApp.$api.user.confirmEmail(confirmationToken)
  }

  const sendAccessGrantedReceiptEmail = async (
    userId: number,
    receiptPapers: string
  ) => {
    return await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
      userId,
      receiptPapers
    )
  }

  return {
    confirmEmailJwt,
    confirmEmail,
    sendAccessGrantedReceiptEmail,
  }
}
